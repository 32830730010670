import React from "react";

import { Avatar, Button, Grow, MenuList, Paper, Popper, ClickAwayListener, MenuItem } from "@material-ui/core";
import { deepPurple } from '@material-ui/core/colors';

/**
 * 
 * @typedef {Object} Opcion
 * @property {string} label - Etiqueta de opción
 * @property {() => void} onClick - Especifica la función a ser ejecutada al hacer click en opción
 */

/**
 * 
 * @typedef {Object} Props
 * @property {string} label Iniciales a mostrar
 * @property {Opcion[]} menuItems Lista de opciones
 */

/**
 * Menu avatar
 * @param {Props} props
 * @param {ChildrenFn} props.children
 * @returns 
 */
const AvatarMenu = ({ 
    label = '',
    menuItems = [], 
}) => {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const prevOpen = React.useRef(open);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleListKeyDown = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    };

    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <div style={{ display: 'flex' }}>
            <Button
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                disableElevation={true}
            >
                <Avatar style={{ backgroundColor: deepPurple[500] }}>
                    {label}
                </Avatar>
            </Button>

            <Popper open={open} onClick={handleClose} anchorEl={anchorRef.current} role={undefined} transition disablePortal={false} style={{ zIndex: 10000 }}>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList 
                                    id="menu-list-grow"
                                    autoFocusItem={open} 
                                    onKeyDown={handleListKeyDown}
                                >
                                    {menuItems.map((x, i) => (
                                        <MenuItem 
                                            key={`avm_${i}`} 
                                            onClick={x.onClick}
                                        >
                                            {x.label}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );
};

export default AvatarMenu;
